<template>
	<el-table class="jscx-table" height="auto" ref="multipleTable" :data="tableData" @select="pinSelect"
		@sort-change="$emit('sortChange', $event)" size="mini" border @selection-change="handleSelectionChange"
		:header-row-style="headerRowStyle" :cell-style="cellStyle" :row-style="rowStyle" :default-sort="defaultSort">
		<el-table-column type="selection" width="55" :selectable="rowSelectable"></el-table-column>
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			:min-width="tableColumn.width || 100" align="center" :sortable="tableColumn.sortable"
			:formatter="cellFormatter">
			<template slot-scope="scope">
				<template v-if="scope.column.property==='status'">
					<div v-if="scope.row['adjustType'] === 0"></div>
					<el-button v-else-if="scope.row['status'] === 0" size="mini" type="text"
						@click="confirmPlan(scope.row)">确认调整</el-button>
					<div v-else>已调整</div>
				</template>
				<template v-else>
					{{cellFormatter(scope.row, scope.column, scope.row[scope.column.property], scope.$index)}}
				</template>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			},
			tableColumns: {
				type: Array,
				default: []
			},
			confirmPlan: {
				type: Function,
				default: null
			},
			sortBy: {
				type: Object,
				default: null
			}
		},
		computed: {
			defaultSort() {
				return this.sortBy !== null ? {prop: this.sortBy.orderField, order: this.sortBy.orderDirection === 'ASC' ? 'ascending': 'descending'} : {}
			}
		},
		methods: {
			doLayout() {
				this.$refs.multipleTable.doLayout()
			},
			cellFormatter(row, column, cellValue, index) {
				if (/(l2dProfitRate)/i.test(column.property)) {
					return (Number(cellValue) * 100).toFixed(2) + '%'
					// const l2d_profit_rate = (Number(row.revenue2) - Number(row.cost2)) / Number(row.cost2)
					// return isFinite(l2d_profit_rate) ? (l2d_profit_rate * 100).toFixed(2) + '%' : '0.00%'
				} else if (/(profitRate)/i.test(column.property)) {
					return (Number(cellValue) * 100).toFixed(2) + '%'
					// const profit_rate = (Number(row.revenue) - Number(row.cost)) / Number(row.cost)
					// return isFinite(profit_rate) ? (profit_rate * 100).toFixed(2) + '%' : '0.00%'
				} else if (/(budget)|(cost)|(revenue)|(bid)|(cpc)|(epc)/i.test(column.property)) {
					return Number(cellValue || null).toFixed(2)
				} else if (/(conversion)/i.test(column.property)) {
					return Number(cellValue || null).toFixed(0)
				} else if (/createdAt/i.test(column.property)) {
					return Moment(Number(cellValue || null)).format('YYYY-MM-DD HH:mm:ss')
				}
				return cellValue
			},
			rowSelectable(row, index) {
				return (row['adjustType'] !== 0 || row['status'] !== 0)
			},
			headerRowStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return {
					'font-size': '10px',
					'text-transform': 'capitalize'
				}
			},
			cellStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				const profit_rate = Number(row.profitRate)
				if (profit_rate > 0) {
					// win
					return {
						'background': '#f0f9eb',
					}
				} else if (profit_rate < 0) {
					// lose
					return {
						'background': '#FCEDEB'
					}
				}
				return {}
			},
			rowStyle({
				row,
				rowIndex
			}) {
				return {
					'font-size': '10px'
				}
			}
		}
	}
</script>

<style>
	.jscx-table.el-table .el-table__body tr:hover>td.el-table__cell {
		background-color: unset;
	}
</style>