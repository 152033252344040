import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				date: [
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
				]
			},
			tableData: [],
			namespace: '',
			fetchOnCreated: false,
			q: {
				startDate: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				endDate: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
			},
			sort_by: null
		}
	},
	created() {
		const {
			startDate,
			endDate,
			orderDirection,
			orderField
		} = this.$route.query
		if (startDate && endDate && /\d{4}-\d{2}-\d{2}/.test(startDate) && /\d{4}-\d{2}-\d{2}/.test(endDate)) {
			this.queryForm.date = [startDate, endDate]
			this.q.startDate = startDate
			this.q.endDate = endDate
		}
		if (/(cost)|(conversions)|(adsConversions)|(bid)|(cpc)|(epc)|(revenue)|(profitRate)|(l2dProfitRate)|(createDays)/.test(orderField) && /(ASC)|(DESC)/.test(orderDirection)) {
			this.sort_by = {orderField, orderDirection}
		}
		this.searchEvent()
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
					if (key === 'date') {
						const [startDate, endDate] = queryForm[key]
						query['startDate'] = startDate
						query['endDate'] = endDate
					} 
				}
			}
			if (this.sort_by) {
				query['orderDirection'] = this.sort_by.orderDirection
				query['orderField'] = this.sort_by.orderField
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent() {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			this.page.current = 1
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		handleList(content) {
			this.tableData = content
		},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/fetchJSCXPlan`, {
				params: {
					...this.q,
					...this.page
				},
				trigger
			})
		},
		downloadTask() {
			const q = this.buildQueryForm(this.queryForm)
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/fetchJSCXPlan`,
				payload: {...q},
				title: `JSCX-Plan(${q.startDate}~${q.endDate})`,
				pagable: {page: 1, size: 50},
				dispatch: this.$store.dispatch,
				requestHandler() {
					const params = Array.from(arguments).reduce((lhs, rhs) => {
						return {...lhs, ...rhs}
					}, {})
					return {params}
				}
			})
		}
	}
}